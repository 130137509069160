import { useCallback, useState } from "react";
import useWeb3 from "./useWeb3";
import { useWeb3React } from "@web3-react/core";
import Environment from "../utils/Environment";
import {
  getdoproundcreatecontract,
  getGemsPreSaleContract,
  getIncentivPreSaleContract,
  getLEIAPreSaleContract,
  getOlympusPreSaleContract,
  getNFAPreSaleContract,
} from "../utils/contractHelpers";
const DiscountedPrice = () => {
  //   const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.DOPRoundSingleContract;
  const contract = getdoproundcreatecontract(tokenAddress, web3);

  const gemsAddress = Environment.GemsPreSale;
  const incentivAddress = Environment.IncentivPreSale;
  const LEIAAddress = Environment.LEIAPreSale;
  const OlympusAddress = Environment.OlympusClaim;
  const NFAAddress = Environment.NFAClaim;

  const gemsContractPresale = getGemsPreSaleContract(gemsAddress, web3);
  const incentivContractPresale = getIncentivPreSaleContract(
    incentivAddress,
    web3
  );

  const LEIAContractPresale = getLEIAPreSaleContract(LEIAAddress, web3);
  const OlympusContractPresale = getOlympusPreSaleContract(
    OlympusAddress,
    web3
  );
  const NFAContractPresale = getNFAPreSaleContract(NFAAddress, web3);

  const project = localStorage.getItem("project");

  const discountedPrice = useCallback(
    async (round, address) => {
      if (project == "INCENTIV") {
        try {
          const buy = await incentivContractPresale.methods
            .allowedTokens(round, address)
            .call();

          const price = parseInt(buy[1]) / 10 ** 18;
          return price;
        } catch (error) {
          throw error;
        }
      } else if (project == "NFA") {
        try {
          const buy = await NFAContractPresale.methods
            .allowedTokens(round, address)
            .call();
          console.log("response hook descoinadsfa: ", buy);

          const price = parseInt(buy[1]) / 10 ** 18;
          return price;
        } catch (error) {
          throw error;
        }
      } else if (project == "LEIA") {
        try {
          const buy = await LEIAContractPresale.methods
            .allowedTokens(round, address)
            .call();

          const price = parseInt(buy[1]) / 10 ** 18;
          return price;
        } catch (error) {
          throw error;
        }
      } else if (project == "OLYMPUS") {
        try {
          const buy = await OlympusContractPresale.methods
            .allowedTokens(round, address)
            .call();

          const price = parseInt(buy[1]) / 10 ** 18;
          return price;
        } catch (error) {
          throw error;
        }
      } else if (project == "GEMS") {
        try {
          const buy = await gemsContractPresale.methods
            .allowedTokens(round, address)
            .call();

          const price = parseInt(buy[1]) / 10 ** 18;
          return price;
        } catch (error) {
          throw error;
        }
      } else {
        try {
          const buy = await contract.methods
            .allowedTokens(round, address)
            .call();

          const price = parseInt(buy[1]) / 10 ** 18;
          return price;
        } catch (error) {
          throw error;
        }
      }
    },
    [contract, account, web3]
  );
  return { discountedPrice: discountedPrice };
};
export default DiscountedPrice;

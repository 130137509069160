import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getclaimscontract } from "../../utils/contractHelpers";
import { getclaimsnewcontract } from "../../utils/contractHelpers";
import { getClaimNewLatest } from "../../utils/contractHelpers";
import {
  getGemsClaimsContract,
  getIncentivClaimsContract,
  getLEIAClaimsContract,
  getOlympusClaimsContract,
  getNFAClaimsContract,
} from "../../utils/contractHelpers";

import { useWeb3React } from "@web3-react/core";

const AllowClaimingHook = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.Claims;
  const tokenAddressNew = environment.ClaimsNew;
  const tokenAddressTomi = environment.claimNewLatest;
  const GemsContractAddress = environment.GemsClaim;
  const IncentivContractAddress = environment.IncentivClaim;
  const LEIAContractAddress = environment.LEIAClaim;
  const OlympusContractAddress = environment.OlympusClaim;
  const NFAContractAddress = environment.NFAClaim;

  const { account } = useWeb3React();
  const contract = getclaimscontract(tokenAddress, web3);
  const contractNew = getclaimsnewcontract(tokenAddressNew, web3);
  const contractNewLatest = getClaimNewLatest(tokenAddressTomi, web3);
  const gemsContract = getGemsClaimsContract(GemsContractAddress, web3);
  const incentivContract = getIncentivClaimsContract(
    IncentivContractAddress,
    web3
  );
  const LEIAContract = getLEIAClaimsContract(LEIAContractAddress, web3);
  const OlympusContract = getOlympusClaimsContract(
    OlympusContractAddress,
    web3
  );
  const NFAContract = getNFAClaimsContract(NFAContractAddress, web3);

  const project = localStorage.getItem("project");
  const allowClaimingHook = useCallback(
    async (bool, round, decesion) => {
      if (project == "INCENTIV") {
        try {
          if (bool) {
            const details = await incentivContract.methods
              .isEnabled(round)
              .call();
            return details;
          } else {
            const gas = await incentivContract.methods
              .enableClaims(round, decesion)
              .estimateGas({ from: account });
            const details = await incentivContract.methods
              .enableClaims(round, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } catch (error) {
          throw error;
        }
      } else if (project == "NFA") {
        try {
          if (bool) {
            const details = await NFAContract.methods.isEnabled(round).call();
            return details;
          } else {
            const gas = await NFAContract.methods
              .enableClaims(round, decesion)
              .estimateGas({ from: account });
            const details = await NFAContract.methods
              .enableClaims(round, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } catch (error) {
          throw error;
        }
      } else if (project == "LEIA") {
        try {
          if (bool) {
            const details = await LEIAContract.methods.isEnabled(round).call();
            return details;
          } else {
            const gas = await LEIAContract.methods
              .enableClaims(round, decesion)
              .estimateGas({ from: account });
            const details = await LEIAContract.methods
              .enableClaims(round, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } catch (error) {
          throw error;
        }
      } else if (project == "OLYMPUS") {
        try {
          if (bool) {
            const details = await OlympusContract.methods
              .isEnabled(round)
              .call();
            return details;
          } else {
            const gas = await OlympusContract.methods
              .enableClaims(round, decesion)
              .estimateGas({ from: account });
            const details = await OlympusContract.methods
              .enableClaims(round, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } catch (error) {
          throw error;
        }
      } else if (project == "GEMS") {
        try {
          if (bool) {
            const details = await gemsContract.methods.isEnabled(round).call();
            return details;
          } else {
            const gas = await gemsContract.methods
              .enableClaims(round, decesion)
              .estimateGas({ from: account });
            const details = await gemsContract.methods
              .enableClaims(round, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } catch (error) {
          throw error;
        }
      } else {
        if (parseInt(round) < 3) {
          try {
            if (bool) {
              const details = await contract.methods.isEnabled(round).call();
              return details;
            } else {
              const gas = await contract.methods
                .updateEnable(round, decesion)
                .estimateGas({ from: account });
              const details = await contract.methods
                .updateEnable(round, decesion)
                .send({
                  from: account,
                  gas,
                });
              return details;
            }
          } catch (error) {
            throw error;
          }
        } else if (parseInt(round) > 6) {
          try {
            if (bool) {
              const details = await contractNewLatest.methods
                .isEnabled(round)
                .call();
              return details;
            } else {
              const gas = await contractNewLatest.methods
                .enableClaims(round, decesion)
                .estimateGas({ from: account });
              const details = await contractNewLatest.methods
                .enableClaims(round, decesion)
                .send({
                  from: account,
                  gas,
                });
              return details;
            }
          } catch (error) {
            throw error;
          }
        } else {
          try {
            if (bool) {
              const details = await contractNew.methods.isEnabled(round).call();
              return details;
            } else {
              const gas = await contractNew.methods
                .updateEnable(round, decesion)
                .estimateGas({ from: account });
              const details = await contractNew.methods
                .updateEnable(round, decesion)
                .send({
                  from: account,
                  gas,
                });
              return details;
            }
          } catch (error) {
            throw error;
          }
        }
      }
    },
    [contract]
  );
  return { allowClaimingHook: allowClaimingHook };
};
export default AllowClaimingHook;

import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import tableup from "../../../assets/tableup.svg";
import tabledown from "../../../assets/tabledown.svg";
import { Accordion } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactPaginate from "react-paginate";

const Datatable = ({
  agents,
  setAgents,
  pages,
  handlePageChange,
  page,

  pageCount,
  count,
  limit,
  offset,
  currentPage,
  GetAllRounds,

  sub,
  setOrderDirection,
  setOrderField,
  orderDirection,
  orderField,
  tomiPrice,
  usdPrice,
  btcPrice,
  pagination,
  leadersLoader,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredIndex1, setHoveredIndex1] = useState(null);
  const project = localStorage.getItem("project");

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  function convertDateFormat(input) {
    const date = new Date(input);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    return `${day}/${month}/${year}\n${hours}:${minutes} UTC`;
  }

  const [copy, setCopy] = useState(false);
  const textCopiedFun2 = (index) => {
    setCopy(index);

    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  const [tooltipData, setTooltipData] = useState({});

  const handleMouseEnter = (text, key, event) => {
    setTooltipData({ text, key });
  };

  const handleMouseLeave = () => {
    setTooltipData({});
  };
  function formatTokenValue(tokenValue) {
    if (tokenValue >= 1000000) {
      return (tokenValue / 1000000).toFixed(2) + "M";
    } else if (tokenValue >= 1000) {
      return (tokenValue / 1000).toFixed(2) + "k";
    } else {
      return (tokenValue || 0)?.toFixed(2);
    }
  }
  return (
    <>
      <section className="datatable">
        <div className="bgtable">
          <div className="table-responsive" style={{ position: "relative" }}>
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div
                      className="parent-tag"
                      onMouseEnter={(e) => handleMouseEnter("name", "th1", e)}
                      onMouseLeave={handleMouseLeave}
                    >
                      NAME{" "}
                      <div
                        className="filter-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderField("name");
                          setOrderDirection((prev) =>
                            prev == -1 ? 1 : prev == 1 ? -1 : -1
                          );
                        }}
                      >
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                            fill={
                              orderField == "name" && orderDirection == -1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                            fill={
                              orderField == "name" && orderDirection == 1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                      </div>
                      {tooltipData.key === "th1" && (
                        <div className="tooltip-popup">{tooltipData.text}</div>
                      )}
                    </div>
                  </th>
                  <th>
                    <div
                      className="parent-tag"
                      onMouseEnter={(e) =>
                        handleMouseEnter("Access Code", "th2", e)
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      CODE{" "}
                      <div
                        className="filter-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderField("email");
                          setOrderDirection((prev) =>
                            prev == -1 ? 1 : prev == 1 ? -1 : -1
                          );
                        }}
                      >
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                            fill={
                              orderField == "email" && orderDirection == -1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                            fill={
                              orderField == "email" && orderDirection == 1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                      </div>
                      {tooltipData.key === "th2" && (
                        <div className="tooltip-popup">{tooltipData.text}</div>
                      )}
                    </div>
                  </th>
                  <th>
                    <div
                      className="parent-tag"
                      onMouseEnter={(e) =>
                        handleMouseEnter("Commissions", "th3", e)
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      %{" "}
                      <div
                        className="filter-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderField("commission");
                          setOrderDirection((prev) =>
                            prev == -1 ? 1 : prev == 1 ? -1 : -1
                          );
                        }}
                      >
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                            fill={
                              orderField == "commission" && orderDirection == -1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                            fill={
                              orderField == "commission" && orderDirection == 1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                      </div>
                      {tooltipData.key === "th3" && (
                        <div className="tooltip-popup">{tooltipData.text}</div>
                      )}
                    </div>
                  </th>
                  {/* <th>
                  <div className="parent-tag">
                    Role{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("role");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                          fill={
                            orderField == "role" && orderDirection == -1
                              ? "black"
                              : "#DADADA"
                          }
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                          fill={
                            orderField == "role" && orderDirection == 1
                              ? "black"
                              : "#DADADA"
                          }
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    God leader{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("godAgent");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                          fill={
                            orderField == "godAgent" && orderDirection == -1
                              ? "black"
                              : "#DADADA"
                          }
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                          fill={
                            orderField == "godAgent" && orderDirection == 1
                              ? "black"
                              : "#DADADA"
                          }
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Wallet Address{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("walletAddress");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                          fill={
                            orderField == "walletAddress" &&
                              orderDirection == -1
                              ? "black"
                              : "#DADADA"
                          }
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                          fill={
                            orderField == "walletAddress" && orderDirection == 1
                              ? "black"
                              : "#DADADA"
                          }
                        />
                      </svg>
                    </div>
                  </div>
                </th> */}
                  <th>
                    <div
                      className="parent-tag"
                      onMouseEnter={(e) =>
                        handleMouseEnter("Number of Purchases", "th4", e)
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      PURCHASES{" "}
                      <div
                        className="filter-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderField("directSales");
                          setOrderDirection((prev) =>
                            prev == -1 ? 1 : prev == 1 ? -1 : -1
                          );
                        }}
                      >
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                            fill={
                              orderField == "directSales" &&
                              orderDirection == -1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                            fill={
                              orderField == "directSales" && orderDirection == 1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                      </div>
                      {tooltipData.key === "th4" && (
                        <div className="tooltip-popup">{tooltipData.text}</div>
                      )}
                    </div>
                  </th>
                  {/* <th>
                  <div className="parent-tag">
                    Token Purchased{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("dopPurchased");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                          fill={
                            orderField == "dopPurchased" && orderDirection == -1
                              ? "black"
                              : "#DADADA"
                          }
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                          fill={
                            orderField == "dopPurchased" && orderDirection == 1
                              ? "black"
                              : "#DADADA"
                          }
                        />
                      </svg>
                    </div>
                  </div>
                </th> */}
                  <th>
                    <div
                      className="parent-tag"
                      onMouseEnter={(e) =>
                        handleMouseEnter("Leader Earnings", "th5", e)
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      EARNINGS{" "}
                      <div
                        className="filter-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderField("totalAmountEarned");
                          setOrderDirection((prev) =>
                            prev == -1 ? 1 : prev == 1 ? -1 : -1
                          );
                        }}
                      >
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                            fill={
                              orderField == "totalAmountEarned" &&
                              orderDirection == -1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                            fill={
                              orderField == "totalAmountEarned" &&
                              orderDirection == 1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                      </div>
                      {tooltipData.key === "th5" && (
                        <div className="tooltip-popup">{tooltipData.text}</div>
                      )}
                    </div>
                  </th>
                  <th>
                    <div
                      className="parent-tag"
                      onMouseEnter={(e) =>
                        handleMouseEnter("MY EARNINGS", "th6", e)
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      MY EARNINGS{" "}
                      <div
                        className="filter-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderField("myEarnings");
                          setOrderDirection((prev) =>
                            prev == -1 ? 1 : prev == 1 ? -1 : -1
                          );
                        }}
                      >
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                            fill={
                              orderField == "myEarnings" && orderDirection == -1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                            fill={
                              orderField == "myEarnings" && orderDirection == 1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                      </div>
                      {tooltipData.key === "th6" && (
                        <div className="tooltip-popup">{tooltipData.text}</div>
                      )}
                    </div>
                  </th>

                  <th>
                    <div
                      className="parent-tag"
                      onMouseEnter={(e) =>
                        handleMouseEnter("TOTAL SALES", "th7", e)
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      TOTAL SALES{" "}
                      <div
                        className="filter-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderField("directAmountInUsd");
                          setOrderDirection((prev) =>
                            prev == -1 ? 1 : prev == 1 ? -1 : -1
                          );
                        }}
                      >
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                            fill={
                              orderField == "directAmountInUsd" &&
                              orderDirection == -1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                            fill={
                              orderField == "directAmountInUsd" &&
                              orderDirection == 1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                      </div>
                      {tooltipData.key === "th7" && (
                        <div className="tooltip-popup">{tooltipData.text}</div>
                      )}
                    </div>
                  </th>
                  <th>
                    <div
                      className="parent-tag"
                      onMouseEnter={(e) =>
                        handleMouseEnter("Direct/Indirect Sales", "th8", e)
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      DIRECT/INDEIRECT{" "}
                      <div
                        className="filter-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderField("inDirectToken");
                          setOrderDirection((prev) =>
                            prev == -1 ? 1 : prev == 1 ? -1 : -1
                          );
                        }}
                      >
                        {/* <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                            fill={
                              orderField == "inDirectToken" &&
                              orderDirection == -1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                            fill={
                              orderField == "inDirectToken" &&
                              orderDirection == 1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg> */}
                      </div>
                      {tooltipData.key === "th8" && (
                        <div className="tooltip-popup">{tooltipData.text}</div>
                      )}
                    </div>
                  </th>
                  <th>
                    <div
                      className="parent-tag"
                      onMouseEnter={(e) =>
                        handleMouseEnter("Token Amount", "th9", e)
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      AMOUNT{" "}
                      <div
                        className="filter-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderField("directToken");
                          setOrderDirection((prev) =>
                            prev == -1 ? 1 : prev == 1 ? -1 : -1
                          );
                        }}
                      >
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                            fill={
                              orderField == "directToken" &&
                              orderDirection == -1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                            fill={
                              orderField == "directToken" && orderDirection == 1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                      </div>
                      {tooltipData.key === "th9" && (
                        <div className="tooltip-popup">{tooltipData.text}</div>
                      )}
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      View Details
                      <div className="filter-btn">
                        {/* <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" /> */}
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {agents?.length > 0 ? (
                  agents?.map((item, index) => {
                    return (
                      <tr>
                        <td className="cursor:pointer">
                          <Link to={`/viewdetail?id=${item?._id}`}>
                            <div className="leadermaindata">
                              <div className="leadermainimg">
                                <img
                                  src={
                                    item.imgUrl
                                      ? item.imgUrl
                                      : "/assets/myteam/image.png"
                                  }
                                  alt="leaderinnerimg"
                                  className="leaderinnerimg"
                                />
                              </div>
                              <div className="leadertext">
                                <h6 className="leadermainhead">{item?.name}</h6>
                                <p className="leadermainpara">
                                  {item?.role === "mini_agent"
                                    ? "Ambassador"
                                    : item?.role === "god_agent"
                                    ? "God Leader"
                                    : item?.role === "super_agent"
                                    ? "Super Leader"
                                    : item?.role === "agent"
                                    ? "Leader"
                                    : item?.role === "mega_agent"
                                    ? "Mega Leader"
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </td>
                        <td>
                          <p className="codepara">
                            <CopyToClipboard
                              text={item?.accessCode}
                              onCopy={(e) => textCopiedFun2(index)}
                            >
                              <p>
                                {item?.accessCode}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="19"
                                  height="19"
                                  viewBox="0 0 19 19"
                                  fill="none"
                                  className={copy === index && "copy-button "}
                                >
                                  <path
                                    d="M6.95508 7.3623L6.95508 5.26245C6.95508 4.42237 6.95508 4.00202 7.11857 3.68115C7.26238 3.39891 7.49168 3.1696 7.77393 3.02579C8.09479 2.8623 8.51515 2.8623 9.35522 2.8623L13.5552 2.8623C14.3953 2.8623 14.8151 2.8623 15.136 3.02579C15.4182 3.16961 15.6479 3.39891 15.7917 3.68115C15.9552 4.00202 15.9552 4.42206 15.9552 5.26214L15.9552 9.46216C15.9552 10.3022 15.9552 10.7223 15.7917 11.0431C15.6479 11.3254 15.418 11.5552 15.1358 11.699C14.8152 11.8623 14.3958 11.8623 13.5574 11.8623L11.4551 11.8623M6.95508 7.3623L4.85522 7.3623C4.01515 7.3623 3.59479 7.3623 3.27393 7.52579C2.99168 7.66961 2.76238 7.89891 2.61857 8.18115C2.45508 8.50202 2.45508 8.92237 2.45508 9.76245L2.45508 13.9625C2.45508 14.8025 2.45508 15.2224 2.61857 15.5432C2.76238 15.8255 2.99168 16.0552 3.27393 16.199C3.59448 16.3623 4.01432 16.3623 4.85276 16.3623L9.05774 16.3623C9.89618 16.3623 10.3154 16.3623 10.636 16.199C10.9182 16.0552 11.1479 15.8252 11.2917 15.543C11.4551 15.2224 11.4551 14.8031 11.4551 13.9646L11.4551 11.8623M6.95508 7.3623L9.05523 7.3623C9.8953 7.3623 10.3151 7.3623 10.636 7.52579C10.9182 7.66961 11.1479 7.89891 11.2917 8.18115C11.4551 8.50171 11.4551 8.92156 11.4551 9.76001L11.4551 11.8623"
                                    stroke="#C4C4C4"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </p>
                            </CopyToClipboard>
                            {copy === index && (
                              <p className="copy-text">Copied</p>
                            )}
                          </p>
                        </td>
                        <td>{item?.commission}%</td>
                        <td>{item?.directSales}</td>
                        <td>
                          {
                            <p className="set-tooltip-custom">
                              $ {formatTokenValue(item?.totalAmountEarned)}{" "}
                              <div className="set-inner-tooltip">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="19"
                                  height="19"
                                  viewBox="0 0 19 19"
                                  style={{ cursor: "pointer" }}
                                  fill={
                                    hoveredIndex1 === index ? "#C4C4C4" : "none"
                                  }
                                  onMouseEnter={() => setHoveredIndex1(index)}
                                  onMouseLeave={() => setHoveredIndex1(null)}
                                >
                                  <path
                                    d="M9.74512 15.833C11.3364 15.833 12.8625 15.2009 13.9878 14.0756C15.113 12.9504 15.7451 11.4243 15.7451 9.83301C15.7451 8.24171 15.113 6.71558 13.9878 5.59037C12.8625 4.46515 11.3364 3.83301 9.74512 3.83301C8.15382 3.83301 6.62769 4.46515 5.50248 5.59037C4.37726 6.71558 3.74512 8.24171 3.74512 9.83301C3.74512 11.4243 4.37726 12.9504 5.50248 14.0756C6.62769 15.2009 8.15382 15.833 9.74512 15.833ZM9.74512 17.333C5.60287 17.333 2.24512 13.9753 2.24512 9.83301C2.24512 5.69076 5.60287 2.33301 9.74512 2.33301C13.8874 2.33301 17.2451 5.69076 17.2451 9.83301C17.2451 13.9753 13.8874 17.333 9.74512 17.333ZM8.99512 9.08301L8.99512 13.583L10.4951 13.583L10.4951 9.08301L8.99512 9.08301ZM8.99512 6.08301L10.4951 6.08301L10.4951 7.58301L8.99512 7.58301L8.99512 6.08301Z"
                                    fill="#C4C4C4"
                                  />
                                </svg>
                                {hoveredIndex1 === index && (
                                  <>
                                    <div className="parent-tooltip">
                                      <div className="twice-elem">
                                        <p>ETH</p>
                                        <h6>
                                          {item?.myEarningsEth.toFixed(4)} ETH
                                        </h6>
                                      </div>
                                      <div className="twice-elem">
                                        <p>DOP</p>
                                        <h6>
                                          {item?.myEarningsDopToken
                                            ? item?.myEarningsDopToken.toFixed(
                                                2
                                              )
                                            : "0"}{" "}
                                          DOP
                                        </h6>
                                      </div>

                                      <div className="twice-elem">
                                        <p>TOMI</p>
                                        <h6>
                                          {item?.myEarningsTomi
                                            ? item?.myEarningsTomi.toFixed(2)
                                            : "0"}{" "}
                                          TOMI
                                        </h6>
                                      </div>

                                      <div className="twice-elem">
                                        <p>USDT</p>
                                        <h6>
                                          {" "}
                                          {item?.myEarningsUsdt
                                            ? item?.myEarningsUsdt.toFixed(2)
                                            : "0"}{" "}
                                          USDT
                                        </h6>
                                      </div>
                                      <div className="twice-elem">
                                        <p>WBTC</p>
                                        <h6>
                                          {" "}
                                          {item?.myEarningsWbtc
                                            ? item?.myEarningsWbtc.toFixed(5)
                                            : "0"}{" "}
                                          WBTC
                                        </h6>
                                      </div>
                                      <div className="twice-elem">
                                        <p>USDC</p>
                                        <h6>
                                          {" "}
                                          {item?.myEarningsUsdc
                                            ? item?.myEarningsUsdc.toFixed(2)
                                            : "0"}{" "}
                                          USDC
                                        </h6>
                                      </div>

                                      <div className="twice-elem">
                                        <p>PEPE</p>
                                        <h6>
                                          {" "}
                                          {item?.myEarningsPepe
                                            ? item?.myEarningsPepe.toFixed(2)
                                            : "0"}{" "}
                                          PEPE
                                        </h6>
                                      </div>

                                      <div className="twice-elem">
                                        <p>LINK</p>
                                        <h6>
                                          {" "}
                                          {item?.myEarningsLink
                                            ? item?.myEarningsLink.toFixed(2)
                                            : "0"}{" "}
                                          LINK
                                        </h6>
                                      </div>

                                      <div className="twice-elem">
                                        <p>UNI</p>
                                        <h6>
                                          {" "}
                                          {item?.myEarningsUni
                                            ? item?.myEarningsUni.toFixed(2)
                                            : "0"}{" "}
                                          UNI
                                        </h6>
                                      </div>

                                      <div className="twice-elem">
                                        <p>GEMS</p>
                                        <h6>
                                          {" "}
                                          {item?.myEarningsUni
                                            ? item?.myEarningsGems.toFixed(2)
                                            : "0"}{" "}
                                          GEMS
                                        </h6>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </p>
                          }
                        </td>
                        <td>
                          {
                            <p>
                              $ {formatTokenValue(item?.myEarnings)}
                              {/* {user?.role == "mega_agent"
                                ? formatTokenValue(
                                    parseFloat(item?.megaAgentsAmountUsd)
                                  )
                                : user?.role === "god_agent"
                                ? formatTokenValue(
                                    parseFloat(item?.godAgentsAmountUsd)
                                  )
                                : user?.role === "super_agent"
                                ? formatTokenValue(
                                    parseFloat(item?.superAgentsAmountUsd)
                                  )
                                : user?.role === "agent"
                                ? formatTokenValue(
                                    parseFloat(item?.agentsAmountUsd)
                                  )
                                : "0"} */}
                            </p>
                          }
                        </td>
                        <td>
                          <p className="set-tooltip-custom">
                            $ {formatTokenValue(item?.directAmountInUsd)}
                            <div
                              className="set-inner-tooltip"
                              onMouseEnter={() => setHoveredIndex(index)}
                              onMouseLeave={() => setHoveredIndex(null)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                style={{ cursor: "pointer" }}
                                fill={
                                  hoveredIndex === index ? "#C4C4C4" : "none"
                                }
                              >
                                <path
                                  d="M9.74512 15.833C11.3364 15.833 12.8625 15.2009 13.9878 14.0756C15.113 12.9504 15.7451 11.4243 15.7451 9.83301C15.7451 8.24171 15.113 6.71558 13.9878 5.59037C12.8625 4.46515 11.3364 3.83301 9.74512 3.83301C8.15382 3.83301 6.62769 4.46515 5.50248 5.59037C4.37726 6.71558 3.74512 8.24171 3.74512 9.83301C3.74512 11.4243 4.37726 12.9504 5.50248 14.0756C6.62769 15.2009 8.15382 15.833 9.74512 15.833ZM9.74512 17.333C5.60287 17.333 2.24512 13.9753 2.24512 9.83301C2.24512 5.69076 5.60287 2.33301 9.74512 2.33301C13.8874 2.33301 17.2451 5.69076 17.2451 9.83301C17.2451 13.9753 13.8874 17.333 9.74512 17.333ZM8.99512 9.08301L8.99512 13.583L10.4951 13.583L10.4951 9.08301L8.99512 9.08301ZM8.99512 6.08301L10.4951 6.08301L10.4951 7.58301L8.99512 7.58301L8.99512 6.08301Z"
                                  fill="#C4C4C4"
                                />
                              </svg>
                              {hoveredIndex === index && (
                                <div className="parent-tooltip">
                                  <div className="twice-elem">
                                    <p>ETH</p>
                                    <h6>
                                      {item?.totalEth
                                        ? item?.totalEth.toFixed(4)
                                        : "0"}{" "}
                                      ETH
                                    </h6>
                                  </div>
                                  <div className="twice-elem">
                                    <p>DOP</p>
                                    <h6>
                                      {item?.totalDopToken
                                        ? item?.totalDopToken.toFixed(2)
                                        : "0"}{" "}
                                      DOP
                                    </h6>
                                  </div>
                                  <div className="twice-elem">
                                    <p>TOMI</p>
                                    <h6>
                                      {item?.totalTomi
                                        ? item?.totalTomi.toFixed(2)
                                        : "0"}{" "}
                                      TOMI
                                    </h6>
                                  </div>

                                  <div className="twice-elem">
                                    <p>USDT</p>
                                    <h6>
                                      {" "}
                                      {item?.totalUsdt
                                        ? item?.totalUsdt.toFixed(2)
                                        : "0"}{" "}
                                      USDT
                                    </h6>
                                  </div>
                                  <div className="twice-elem">
                                    <p>WBTC</p>
                                    <h6>
                                      {" "}
                                      {item?.totalWbtc
                                        ? item?.totalWbtc.toFixed(5)
                                        : "0"}{" "}
                                      WBTC
                                    </h6>
                                  </div>
                                  <div className="twice-elem">
                                    <p>USDC</p>
                                    <h6>
                                      {" "}
                                      {item?.totalUsdc
                                        ? item?.totalUsdc.toFixed(2)
                                        : "0"}{" "}
                                      USDC
                                    </h6>
                                  </div>

                                  <div className="twice-elem">
                                    <p>PEPE</p>
                                    <h6>
                                      {" "}
                                      {item?.totalPepe
                                        ? item?.totalPepe.toFixed(2)
                                        : "0"}{" "}
                                      PEPE
                                    </h6>
                                  </div>

                                  <div className="twice-elem">
                                    <p>UNI</p>
                                    <h6>
                                      {" "}
                                      {item?.totalUni
                                        ? item?.totalUni.toFixed(2)
                                        : "0"}{" "}
                                      UNI
                                    </h6>
                                  </div>

                                  <div className="twice-elem">
                                    <p>LINK</p>
                                    <h6>
                                      {" "}
                                      {item?.totalLink
                                        ? item?.totalLink.toFixed(2)
                                        : "0"}{" "}
                                      LINK
                                    </h6>
                                  </div>

                                  <div className="twice-elem">
                                    <p>GEMS</p>
                                    <h6>
                                      {" "}
                                      {item?.totalGems
                                        ? item?.totalGems.toFixed(2)
                                        : "0"}{" "}
                                      GEMS
                                    </h6>
                                  </div>
                                </div>
                              )}
                            </div>
                          </p>
                        </td>

                        <td>
                          ${" "}
                          {item?.directAmountInUsd
                            ? formatTokenValue(item?.directAmountInUsd)
                            : "0.0"}
                          / ${" "}
                          {item?.indirectAmountInUsd
                            ? formatTokenValue(item?.indirectAmountInUsd)
                            : "0.0"}
                        </td>
                        <td>
                          {/* <div className="twice-text">
                          <h6>
                            $
                            {parseFloat(
                              item?.totalAmountEarned?.toFixed(2)
                            )?.toLocaleString()}
                          </h6>
                          <p>
                            ({parseFloat(item?.earningInEth?.toFixed(5))} ETH +{" "}
                            {parseFloat(
                              item?.earningInUsdt?.toFixed(2)
                            )?.toLocaleString()}{" "}
                            USDT +{" "}
                            {parseFloat(
                              item?.earningInTomi?.toFixed(4)
                            )?.toLocaleString()}{" "}
                            TOMI +{" "}
                            {parseFloat(
                              item?.earningInWbtc?.toFixed(4)
                            )?.toLocaleString()}{" "}
                            WBTC +{" "}
                            {parseFloat(
                              item?.earningInUsdc?.toFixed(4)
                            )?.toLocaleString()}{" "}
                            USDC)
                          </p>
                        </div> */}
                          {formatTokenValue(item?.directToken)}
                        </td>
                        {/* <td>
                        <div className="twice-text">
                          <h6>
                            $
                            {parseFloat(
                              ((item?.purchasesInEth || 0) * usdPrice || 0) +
                              (item?.purchasesInUsdt || 0) +
                              ((item?.purchasesInTomi || 0) * tomiPrice ||
                                0) +
                              (item?.purchasesInUsdc || 0) +
                              ((item?.purchasesInWbtc || 0) * btcPrice || 0)
                            )
                              ?.toFixed(2)
                              ?.toLocaleString()}
                          </h6>
                          <p>
                            ({parseFloat(item?.purchasesInEth || 0)?.toFixed(5)}{" "}
                            ETH +{" "}
                            {parseFloat(item?.purchasesInUsdt || 0)
                              ?.toFixed(2)
                              ?.toLocaleString()}{" "}
                            USDT +{" "}
                            {parseFloat(item?.purchasesInTomi || 0)
                              ?.toFixed(4)
                              ?.toLocaleString()}{" "}
                            TOMI +{" "}
                            {parseFloat(item?.purchasesInWbtc || 0)
                              ?.toFixed(6)
                              ?.toLocaleString()}{" "}
                            WBTC +{" "}
                            {parseFloat(item?.purchasesInUsdc || 0)
                              ?.toFixed(2)
                              ?.toLocaleString()}{" "}
                            USDC)
                          </p>
                        </div>
                      </td>

                      <td>
                        {parseFloat(
                          (item?.directToken || 0)?.toFixed(2)
                        )?.toLocaleString()}
                      </td>
                      <td>
                        {parseFloat(
                          (item?.inDirectToken || 0)?.toFixed(2)
                        )?.toLocaleString()}
                      </td> */}
                        <td>
                          <Link to={`/viewdetail?id=${item?._id}`}>
                            <div className="viewmain">
                              <p className="viewpara">View</p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M7.56641 12H16.6864M16.6864 12L12.7778 7.88574M16.6864 12L12.7778 16.1143"
                                  stroke="black"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                ) : leadersLoader ? (
                  <tr>
                    <td colSpan="10" className="text-center">
                      <div className="text-center">
                        <div class="d-flex align-items-center justify-content-center">
                          <div class="spinner-border text-info" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      <div className="text-center">
                        <h6
                          className=""
                          style={{
                            color: "#000",
                            textAlign: "center",
                            marginTop: 60,
                          }}
                        >
                          No data found!
                        </h6>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="footer-content">
            <div className="left-f">
              <h6>
                {" "}
                {count > 0 && count <= 10 ? (
                  <>
                    <p>
                      SHOWING 1-{count} OF {count}
                    </p>
                  </>
                ) : count > 10 ? (
                  <>
                    <p>
                      SHOWING {page > 0 ? page * 10 + 1 : page + 1} -
                      {10 * (page + 1) > count ? count : 10 * (page + 1)} OF{" "}
                      {count}
                    </p>
                  </>
                ) : (
                  <>
                    <p>SHOWING 0 OF 0</p>
                  </>
                )}
              </h6>
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <nav aria-label="Page navigation example">
                    <ul class="text-center">
                      <ReactPaginate
                        previousLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <path
                              d="M10 13L5 8L10 3"
                              stroke="#fff"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                        nextLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <path
                              d="M5 13L10 8L5 3"
                              stroke="#fff"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={page}
                      ></ReactPaginate>
                    </ul>
                  </nav>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Datatable;
